import request from '@/utils/request'

export const postSAIBatchOnline = (id, data) => {
  return request({
    method: 'POST',
    url: `cms/shop_activities/${id}/shop_activity_items/batch_online`,
    data
  })
}

export const postSAIBatchOffline = (id, data) => {
  return request({
    method: 'POST',
    url: `cms/shop_activities/${id}/shop_activity_items/batch_offline`,
    data
  })
}

export const postSAIBatchDestroy = (id, data) => {
  return request({
    method: 'POST',
    url: `cms/shop_activities/${id}/shop_activity_items/batch_destroy`,
    data
  })
}
